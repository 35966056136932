#webView-chat{
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#btn-sair{ 
    z-index:4;
    position: relative;
    display: inline;
    
}

.container-logoff{
    position: relative;
    text-align: right;
    margin-right: 1%;
    margin-top: 10px;
}
