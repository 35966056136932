:root {
  --main-color: #134173;
  --link-color: #666666;
  --text1: #999999;
  --highligth-text: #19679e;
  --input-text: #555555;
  --white-color: #FFFFFF;
  --point-list: #CCCCCC;
  --line-input: #D9D9D9;
  --container-color: #FFFFFF;
}
 
/*
Zerar estilos
*/
  
body, 
.container-principal {
  background-color: #243464;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
  overflow: hidden; 
}

* {
      margin: 0px; 
      padding: 0px; 
      box-sizing: border-box;
  }
 

/*
  Container
*/
  
.container-principal {
  width: 100%;
  margin: 0 auto;
}
  
.container-central {
  width: 100%;  
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
  
.container-login {
  width: 380px;
  margin: 50px;
  padding: 60px 25px 60px 25px;
  border-radius: 2%;
  background: var(--white-color);
}

.logo-moura{  
  width: 80%;
  margin: 10px auto;
  display:block
}


/*
  Form
*/
  
.login-form {
  width: 80%;
  margin: auto auto 15px auto;
}


/*
  Inputs
*/

.login-input {
  width: 100%;
  position: relative;
  border-bottom: 2px solid var(--line-input);
}

.input-form {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: var(--input-text);
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 52px;
  background: transparent;
  padding: 0 5px;    
}
#esqueci-senha{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  background: transparent;
  display: block;
  padding: 0 5px;
  margin-bottom: 12px;
  color: #134173; 
  cursor: pointer;
  
}
#header-modal{
  background-color: #134173;
}
#title-modal{
  font-size: 16px;
  color:#fff;


}
#body-Modal{
  font-size: 13px;
}
#redirect-sdm{
  font-family: Arial, Helvetica, sans-serif;
  color: #fff; 
  text-decoration: none;
}
/*
  espaçamento entre o login e senha
*/

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-top-35 {
  margin-top: 35px;
}


/*
  Animações inputs
*/

.focus-input-form {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input-form::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.4s;
  background: var(--main-color);
}

.focus-input-form::after {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: var(--main-color);
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.input-form:focus + .focus-input-form::after {
  top: -20px;
  color: var(--main-color);
  font-size: 15px;
}

.input-form:focus + .focus-input-form::before {
  width: 100%;
}

.has-val.input-form + .focus-input-form::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input-form + .focus-input-form::before {
  width: 100%;
}



/*
  button
*/

.container-login-form-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-form-btn {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: var(--white-color);
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: var(--main-color);
  border-radius: 6px;
  transition: all 0.4s;
}

/*
  Animações button
*/

  
input, button {
  outline: none ;
  border: none;
}
  
.login-form-btn:hover  {
  cursor: pointer;
  background-color: var(--highligth-text);
}
  
  
 
/*
  Texto central
*/
      
  
.text-center{
  font-family: Arial, Helvetica, sans-serif;
  color: var(--input-text);
  font-size: 11px;
  text-align: center;
}

/* campos de erro formulario */
.error{
  font-size: 11px;
  font-weight: 700;
  color: #dc3545;
  font-family: Arial, Helvetica, sans-serif;
}




  

  
 
  
  
  




