#Chatbot-desbloqueio{
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff !important;
}

#info-chat{
    font-size: 17px;
}

